import React from 'react';
import './index.less'


const About = () => {
  return (
    <div className='page'>
      <h1>关于我</h1>
    </div>
  );
};


export default About;
