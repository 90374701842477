
import Index from '../src/pages/index'; 

function App() {
  return (
    <div className="App">
       <Index/>
    </div>
  );
}

export default App;
