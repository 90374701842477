import React from 'react';
import './index.less'
import CarView from './carview';

const Article = () => {
  return (
    <div className='page'>
      {/* <h1>这里要show车</h1> */}
      {/* <CarView /> */}
      </div>
  );
};


export default Article;
